export default {
  'es-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      notation: 'standard',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  en: {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  }
}
