// import { h, resolveComponent } from 'vue'
import { ref } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

/** AUTH CONTROL **/
import FirebaseAuth, { onAuthStateChanged } from '@/firebase/Auth'
const user = ref(null);

/** ROUTES **/
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/Register'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

// Guard de navegación
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !user.value) {
    next({ name: 'Login' })
  } else if (!to.meta.requiresAuth && user.value) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
});

/** MANAGE LOGOUT AND LOGIN **/
onAuthStateChanged(FirebaseAuth, (firebaseUser) => {
  user.value = firebaseUser;
  if (firebaseUser) {
    router.push('/')
  } else {
    router.push('/login')
  }
});

export default router
