// Vue app
import { createApp } from 'vue'
// Translation
import { createI18n } from 'vue-i18n'
import translationList from '@/i18n'
import numberFormat from '@/i18n/numberFormat'
// Pinia and routes
import { createPinia } from 'pinia'
import router from '@/router'
// Theme
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
// Main App
import App from './App.vue'

// Initialize translation

const i18n = createI18n({
  locale: 'es-CO',
  fallbackLocale: 'en',
  messages: translationList,
  numberFormats: numberFormat,
})

// Create app and mount it
const app = createApp(App)
app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.mount('#app')
