export default [
  // {
  //   component: 'CNavItem',
  //   name: 'Inicio',
  //   to: '/dashboard',
  //   icon: 'cil-speedometer',
  //   badge: {
  //     color: 'primary',
  //     text: 'NEW',
  //   },
  // },
  {
    component: 'CNavTitle',
    name: 'Analytics',
  },
  {
    component: 'CNavItem',
    name: 'Productos',
    to: '/dashboard',
    icon: 'cil-basket',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'Favoritos',
    to: '/favorites',
    icon: 'cil-star',
    soon: true,
    badge: {
      text: 'Soon',
      color: 'info'
    }
  },
  {
    component: 'CNavTitle',
    name: 'Information',
  },
  {
    component: 'CNavItem',
    name: 'Notificaciones',
    to: '/notifications',
    icon: 'cil-bell',
    soon: true,
    badge: {
      text: 'Soon',
      color: 'info'
    }
  },
]
