export const firebaseConfig = {
  apiKey: "AIzaSyDgiLbMEaXHrTIuju_4NKrvK8IiEq6oQDQ",
  authDomain: "winner-prod-eco.firebaseapp.com",
  projectId: "winner-prod-eco",
  storageBucket: "winner-prod-eco.appspot.com",
  messagingSenderId: "355730616309",
  appId: "1:355730616309:web:836cffc9c427eb3609fabc",
}

export const serverConfig = {
  protocol: 'https',
  server: 'spywinners.com/api',
}
