<template>
  <CFooter class="px-4">
    <div>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank" v-text="$t('footer.powered')" />
      <a href="https://www.instagram.com/soycamilorozco/" v-text="$t('footer.owner')" />
    </div>
  </CFooter>
</template>
