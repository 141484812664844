import firebaseApp from '@/../firebase.config'
import {
  getAuth,
  onAuthStateChanged,
  setPersistence, browserSessionPersistence, browserLocalPersistence,
  createUserWithEmailAndPassword, signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth"

const FirebaseAuth = getAuth(firebaseApp)

export default FirebaseAuth
export {
  onAuthStateChanged,
  createUserWithEmailAndPassword, signInWithEmailAndPassword,
  setPersistence, browserSessionPersistence, browserLocalPersistence,
  sendPasswordResetEmail,
  signOut,
}
